import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import AkButton from '@atlaskit/button/standard-button';
import type { ButtonProps } from '@atlaskit/button/standard-button';
import { token } from '@atlaskit/tokens';
import AkTooltip from '@atlaskit/tooltip/Tooltip';
import LockedIcon from '@atlaskit/icon/glyph/lock-filled';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useContentType } from '@confluence/page-context';

import { UnlockedIcon } from '../UnlockedIcon';
import {
	getPageRestrictionsExperimentCohort,
	type PageRestrictionsExperimentCohort,
} from '../RestrictionsDialogTrigger/getPageRestrictionsExperimentCohort';
import { areRestrictionsDisabledForContentType } from '../contentTypeRestrictionsUtils';

const i18n = defineMessages({
	noRestrictions: {
		id: 'restrictions.button.no-restrictions.tooltip',
		defaultMessage: 'No restrictions',
		description: 'Tooltip explaining that the shown icon indicates absence of restrictions',
	},
	editRestrictionsApply: {
		id: 'restrictions.button.edit-restrictions-apply.tooltip',
		defaultMessage: 'Editing restricted',
		description:
			"Tooltip explaining the page has 'everyone can view, only some can edit' restriction mode",
	},
	restrictionsApply: {
		id: 'restrictions.button.restrictions-apply.tooltip',
		defaultMessage: 'Restrictions apply',
		description: 'Tooltip explaining that the shown icon indicates presence of restrictions',
	},
	experimentRestrictions: {
		id: 'restrictions.button.experiment-restrictions.tooltip',
		defaultMessage: 'Upgrade to restrict page access',
		description: 'Tooltip explaining that the user must upgrade in order to change restrictions',
	},
	restrictionsNotApplicableForSmartLinkEmbeds: {
		id: 'restrictions.button.smart-link-embed-restrictions.tooltip',
		defaultMessage: 'Additional restrictions can’t be set for Smart Links',
		description:
			"Tooltip explaining that restrictions can't be set on this particular content type",
	},
});

const getButtonContent = (isRestrictionButtonDisabled, defaulti18nMessage) => {
	return isRestrictionButtonDisabled
		? i18n.restrictionsNotApplicableForSmartLinkEmbeds
		: defaulti18nMessage;
};

const useGenerateButtonProps = (
	props: RestrictionButtonComponentProps,
	contentType: string | undefined,
) => {
	const isRestrictionButtonDisabled = areRestrictionsDisabledForContentType(contentType);

	const intl = useIntl();
	if (props.hasDirectViewRestrictions) {
		// locked
		return {
			content: (
				<FormattedMessage
					{...getButtonContent(isRestrictionButtonDisabled, i18n.restrictionsApply)}
				/>
			),
			icon: (
				<LockedIcon
					primaryColor={token('color.icon.danger', '#DE350B')}
					testId="locked-icon"
					label=""
				/>
			),
			ariaLabel: intl.formatMessage(
				getButtonContent(isRestrictionButtonDisabled, i18n.restrictionsApply),
			),
		};
	}
	if (props.hasInheritedViewRestrictions) {
		// unlocked restricted
		return {
			content: (
				<FormattedMessage
					{...getButtonContent(isRestrictionButtonDisabled, i18n.restrictionsApply)}
				/>
			),
			icon: (
				<UnlockedIcon
					primaryColor={token('color.icon.danger', '#DE350B')}
					testId="unlocked-restricted-icon"
					label=""
				/>
			),
			ariaLabel: intl.formatMessage(
				getButtonContent(isRestrictionButtonDisabled, i18n.restrictionsApply),
			),
		};
	}
	// note: the " && !hasDirectViewRestrictions" is excessive, as the have a check above,
	// but it's just easier to read this way.
	if (props.hasRestrictions && !props.hasDirectViewRestrictions) {
		return props.pageRestrictionsExperimentCohort === 'test'
			? {
					content: <FormattedMessage {...i18n.experimentRestrictions} />,
					icon: (
						<UnlockedIcon
							primaryColor={token('color.icon.selected', '#0C66E4')}
							testId="unlocked-icon"
							label=""
						/>
					),
					ariaLabel: intl.formatMessage(i18n.experimentRestrictions),
					ccPageRestrictionsCohort: props.pageRestrictionsExperimentCohort,
				}
			: {
					content: (
						<FormattedMessage
							{...getButtonContent(isRestrictionButtonDisabled, i18n.editRestrictionsApply)}
						/>
					),
					icon: (
						<UnlockedIcon
							primaryColor={token('color.icon', '#42526E')}
							testId="unlocked-icon"
							label=""
						/>
					),
					ariaLabel: intl.formatMessage(
						getButtonContent(isRestrictionButtonDisabled, i18n.editRestrictionsApply),
					),
					ccPageRestrictionsCohort: props.pageRestrictionsExperimentCohort,
				};
	}
	// unlocked
	return props.pageRestrictionsExperimentCohort === 'test'
		? {
				content: <FormattedMessage {...i18n.experimentRestrictions} />,
				icon: (
					<UnlockedIcon
						primaryColor={token('color.icon.selected', '#0C66E4')}
						testId="unlocked-icon"
						label=""
					/>
				),
				ariaLabel: intl.formatMessage(i18n.experimentRestrictions),
				ccPageRestrictionsCohort: props.pageRestrictionsExperimentCohort,
			}
		: {
				content: (
					<FormattedMessage
						{...getButtonContent(isRestrictionButtonDisabled, i18n.noRestrictions)}
					/>
				),
				icon: <UnlockedIcon testId="unlocked-icon" label="" />,
				ariaLabel: intl.formatMessage(
					getButtonContent(isRestrictionButtonDisabled, i18n.noRestrictions),
				),
				ccPageRestrictionsCohort: props.pageRestrictionsExperimentCohort,
			};
};

export type RestrictionButtonComponentProps = {
	hasDirectViewRestrictions: boolean;
	hasInheritedViewRestrictions: boolean;
	hasRestrictions: boolean;
	pageRestrictionsExperimentCohort?: PageRestrictionsExperimentCohort;
	onClick;
} & Pick<ButtonProps, 'isDisabled'>;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExperimentButton = styled(AkButton)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		width: '32px',
		background: `linear-gradient(45deg,
      ${token('color.background.accent.purple.subtlest', '#F3F0FF')},
      ${token('color.background.accent.blue.subtlest', '#E9F2FF')})`,
		'&:hover': {
			background: `linear-gradient(45deg,
        ${token('color.background.accent.purple.subtlest.hovered', '#DFD8FD')},
        ${token('color.background.accent.blue.subtlest.hovered', '#CCE0FF')})`,
		},
		'&:active': {
			background: `linear-gradient(45deg,
        ${token('color.background.accent.purple.subtlest.pressed', '#B8ACF6')},
        ${token('color.background.accent.blue.subtlest.pressed', '#85B8FF')})`,
		},
	},
});

export const RestrictionButtonComponent = (props: RestrictionButtonComponentProps) => {
	const [contentType] = useContentType();

	useEffect(() => {
		if (props.pageRestrictionsExperimentCohort) {
			// fires an experiment exposure event
			getPageRestrictionsExperimentCohort(true);
		}
	}, [props.pageRestrictionsExperimentCohort]);

	const { content, icon, ariaLabel, ccPageRestrictionsCohort } = useGenerateButtonProps(
		props,
		contentType,
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const shouldFirePageRestrictionsExperimentAnalytics =
		ccPageRestrictionsCohort === 'test' || ccPageRestrictionsCohort === 'control';

	useEffect(() => {
		if (shouldFirePageRestrictionsExperimentAnalytics) {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'rendered',
					actionSubject: 'restrictionsButton',
					source: 'pageRestrictionsButton',
					attributes: {
						ccPageRestrictionsCohort,
					},
				},
			}).fire();
		}
	}, [
		ccPageRestrictionsCohort,
		createAnalyticsEvent,
		shouldFirePageRestrictionsExperimentAnalytics,
	]);

	const handleOnClick = (...args) => {
		if (shouldFirePageRestrictionsExperimentAnalytics) {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'restrictionsButton',
					source: 'pageRestrictionsButton',
					attributes: {
						ccPageRestrictionsCohort,
					},
				},
			}).fire();
		}
		props.onClick?.(...args);
	};

	return (
		<AkTooltip content={content}>
			{ccPageRestrictionsCohort === 'test' ? (
				<ExperimentButton
					data-test-id="restrictions.dialog.button"
					onClick={handleOnClick}
					isDisabled={props.isDisabled}
					iconBefore={icon}
					aria-label={ariaLabel}
					data-id="restrictions-button"
				/>
			) : (
				<AkButton
					appearance="subtle"
					data-test-id="restrictions.dialog.button"
					onClick={handleOnClick}
					isDisabled={props.isDisabled}
					iconBefore={icon}
					aria-label={ariaLabel}
					data-id="restrictions-button"
					style={
						// The button's icon's svg isn't in itself forcing a
						// minimum button width, so if we don't manually apply one,
						// it'll get condensed when squeezed (for example by the
						// big web item you'll to its left see on our integration
						// test servers)
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						{ width: '32px' }
					}
				/>
			)}
		</AkTooltip>
	);
};
